<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ currentTable }}</h4>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select class="select-primary mb-3 pagination-select" v-model="pagination.perPage"
                placeholder="Por página">
                <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <router-link to="/categoria" class="btn ml-auto mr-3 mt-0 btn-new">Cadastrar Nova</router-link>

              <base-input>
                <el-input type="search" class="mb-3 search-input" clearable prefix-icon="el-icon-search"
                  placeholder="Procurar item" v-model="searchQuery" aria-controls="datatables">
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData" v-if="total > 0">
              <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                :prop="column.prop" :label="column.label">
              </el-table-column>
              <el-table-column :min-width="60" v-if="total > 0" align="right" label="Ações">
                <div slot-scope="props">
                  <base-button @click.native="edit(props.$index, props.row)" class="edit btn-link" type="warning"
                    size="sm" icon>
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button @click.native="handleDelete(props.$index, props.row)" class="remove btn-link" type="danger"
                    size="sm" icon>
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category table-footer" v-if="total > 0">
                Exibindo de {{ from + 1 }} à {{ to }} de {{ total }} registros
              </p>
              <p class="card-category table-footer" v-if="total === 0">
                Nenhum registro encontrado.
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total" v-if="total > 0">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import { BasePagination } from '@/components'

import Fuse from 'fuse.js'
import swal from 'sweetalert2'

export default {
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      propsToSearch: [],
      tableColumns: [],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    }
  },
  methods: {
    edit(index, row) {
      return this.$router.push(`/categoria/${row.id}`)
    },
    handleDelete(index, row) {
      swal({
        title: 'Você tem certeza?',
        text: `Você está prestes a deletar "${row.title}".`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Sim, eu quero deletar!',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          swal({
            title: 'Deletando',
            text: `Aguarde, estamos deletando "${row.title}".`,
            type: 'info',
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.deleteRow(row).then(status => {
            if (status) {
              swal({
                title: 'Deletado!',
                text: `Você deletou "${row.title}"`,
                type: 'success',
                showConfirmButton: false,
                timer: 2000
              })
            } else {
              swal({
                title: 'Error!',
                text: `Ocorreu um erro ao deletar "${row.title}"`,
                type: 'error',
                confirmButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Ok',
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    async deleteRow(row) {
      let status
      try {
        status = (await this.$http.delete(`categories/${row.id}`)).status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToDelete = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
        return true
      }
      return false
    },
    async getData() {
      this.tableData = (await this.$http.get('categories')).data.categories

      this.tableColumns = [
        {
          prop: 'id',
          label: 'Id',
          minWidth: 40
        },
        {
          prop: 'title',
          label: 'Título',
          minWidth: 200
        },
        {
          prop: 'type',
          label: 'Tipo',
          minWidth: 200
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: 40
        }
      ]

      this.propsToSearch = ['title', 'type']
    }
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.3
    })
    this.getData()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
  },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    queriedData() {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
    currentTable() {
      const msg = `lista de ${this.$route.name}`
      return msg.toUpperCase()
    }
  }
}
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

p.table-footer {
  text-transform: none;
}

.btn-new {
  height: 2.5rem;
}
</style>
